@import "base";
[data-rune="Video"] {
  .vjs_hide {
    display: none;
  }

  .vjs-tech {
    object-fit: cover;
  }
}
