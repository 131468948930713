@import "base";
@import "../../../../../services/MarppleShop/shared/style/base";

.badge_container {
  display: flex;
  position: absolute;
  top: ptr(8);
  right: ptr(8);
  left: ptr(8);
  row-gap: ptr(4);

  .panel {
    display: flex;
    gap: ptr(4);
    flex-wrap: wrap;

    &.right {
      flex: 1;
      justify-content: flex-end;
    }
  }

  .badge {
    display: flex;
    flex-shrink: 0;
  }
}
