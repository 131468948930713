@import "base";
html[is_mobile="false"] section.container {
  position: relative;
  @include flex(center, center, column);
  color: $black;

  @media screen and (width >= 1920px) {
    max-height: ptr(1000);
  }

  .main_image_container {
    width: 100vw;
    height: 100%;

    .image_wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      img.background {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;

        @media screen and (width >= 1920px) {
          max-height: ptr(1000);
        }
      }
    }
  }

  .content_container {
    @include flex(center, center, column);

    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    padding: ptr(144) 0;
    gap: ptr(20);

    .title_container {
      @include flex(center, center, column);
      gap: ptr(32);
      color: $blue-100;
      text-align: center;

      .title_wrapper {
        @include flex(center, center, column);
        gap: ptr(16);
      }

      .date_info {
        @include flex(center, center);
        width: fit-content;
        height: ptr(40);
        padding: 0 ptr(24);
        border-radius: ptr(24);
        background: rgba(28, 117, 255, 0.1);
      }
    }
    .desc_container {
      @include flex(center, center);
      gap: ptr(64);
      .image_container {
        width: ptr(552);
        height: ptr(520);
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }
      .desc_wrapper {
        @include flex(flex-start, flex-start, column);
        gap: ptr(32);
        .detail_title_wrapper {
          @include flex(flex-start, flex-start, column);
          gap: ptr(8);
          color: $blue-100;
          .detail_title_list {
            .detail_title {
              letter-spacing: -0.2px;
              text-decoration-line: underline;
              text-decoration-style: solid;
              text-decoration-skip-ink: auto;
              text-decoration-thickness: auto;
              text-underline-offset: ptr(1);
              text-underline-position: from-font;
            }
          }
        }
        .detail_list {
          @include flex(flex-start, flex-start, column);
          gap: ptr(12);
          .detail_item {
            @include flex(flex-start, center);
            gap: ptr(16);
            .detail_item_header {
              @include flex(center, center);
              width: ptr(120);
              height: ptr(33);
              border-radius: ptr(33);
              background-color: $blue-100;
              color: $white;
            }
          }
        }
        .sub_detail_list {
          @include flex(flex-start, flex-start, column);
          gap: ptr(4);
          color: $gray-80;
        }
        .caution_body {
          min-width: ptr(675);
          padding: ptr(24);
          background-color: $dim-60;
          gap: ptr(4);
          @include flex(flex-start, flex-start, column);
          .caution_item {
            @include flex(flex-start, flex-start);
            color: $white;
            text-align: start;
            gap: ptr(8);
            svg {
              margin-top: ptr(8);
              circle {
                fill: $white;
              }
            }
            &.caution_item_sub {
              padding-left: ptr(16);
            }
          }
        }
      }
    }
  }
}

html[is_mobile="true"] section.container {
  position: relative;
  @include flex(center, center, column);
  color: $black;

  .main_image_container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translate(-50%, -50%);

    .image_wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      img.background {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }

  .content_container {
    @include flex(center, center, column);
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: ptr(96) ptr(24);
    gap: ptr(40);

    .title_container {
      @include flex(center, center, column);
      gap: ptr(16);
      color: $blue-100;
      text-align: center;

      .title_wrapper {
        @include flex(center, center, column);
        gap: ptr(8);
        .main_title {
          line-height: 1.2 !important;
        }
      }

      .date_info {
        @include flex(center, center);
        width: fit-content;
        height: ptr(32);
        padding: 0 ptr(16);
        border-radius: ptr(16);
        background: rgba(28, 117, 255, 0.1);
      }
    }
    .desc_container {
      @include flex(center, center, column);
      gap: ptr(40);
      width: 100%;
      .image_container {
        width: ptr(280);
        height: ptr(263);
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }
      .desc_wrapper {
        width: 100%;
        @include flex(center, center, column);
        gap: ptr(32);
        .detail_title_wrapper {
          width: 100%;
          color: $blue-100;
          text-align: center;
          gap: ptr(8);
          @include flex(center, center, column);
          .detail_title_list {
            .detail_title {
              letter-spacing: -0.2px;
              text-decoration-line: underline;
              text-decoration-style: solid;
              text-decoration-skip-ink: auto;
              text-decoration-thickness: auto;
              text-underline-offset: ptr(1);
              text-underline-position: from-font;
            }
          }
        }
        .detail_list {
          @include flex(flex-start, flex-start, column);
          gap: ptr(12);
          .detail_item {
            @include flex(flex-start, flex-start);
            gap: ptr(12);
            .detail_item_header {
              @include flex(center, center);
              min-width: ptr(90);
              height: ptr(24);
              padding: ptr(5) 0;
              border-radius: ptr(24);
              background-color: $blue-100;
              color: $white;
            }
            .detail_item_body {
              width: 100%;
            }
          }
        }
        .sub_detail_list {
          width: 100%;
          color: $gray-80;
          text-align: center;
          gap: ptr(4);
          @include flex(center, center, column);
        }
        .caution_body {
          width: 100%;
          padding: ptr(16);
          background-color: $dim-60;
          gap: ptr(4);
          @include flex(center, center, column);
          .caution_item {
            @include flex(flex-start, flex-start);
            color: $white;
            text-align: start;
            gap: ptr(8);
            svg {
              margin-top: ptr(8);
              circle {
                fill: $white;
              }
            }
            &.caution_item_sub {
              padding-left: ptr(16);
            }
          }
        }
      }
    }
  }
}
