/* 모든 요소에 대한 기본 스타일 초기화 */
:where([data-rune]) {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* 버튼 요소 */
  button {
    border-width: 0;
    background-color: transparent; /* 배경색 투명 */
    color: inherit; /* 부모 요소의 텍스트 색상 상속 */
    font-size: inherit; /* 부모 요소의 글꼴 크기 상속 */
    line-height: inherit; /* 부모 요소의 줄 간격 상속 */
    cursor: pointer; /* 마우스 커서를 포인터로 변경 */
    appearance: none; /* 버튼의 기본 스타일 제거 */
  }

  /* 링크 요소 */
  a {
    color: inherit; /* 부모 요소의 텍스트 색상 상속 */
    text-decoration: none; /* 밑줄 제거 */
    cursor: pointer; /* 마우스 커서를 포인터로 변경 */
  }

  /* 텍스트 입력 필드 */
  input,
  textarea {
    padding: 0;
    border-width: 0;
    color: inherit; /* 부모 요소의 텍스트 색상 상속 */
    font-size: inherit; /* 부모 요소의 글꼴 크기 상속 */
    line-height: inherit; /* 부모 요소의 줄 간격 상속 */
    appearance: none; /* 기본 스타일 제거 */
  }

  /* 선택된 텍스트 입력 필드 */
  input,
  textarea:focus {
    border-color: #007bff; /* 포커스시 테두리 색상 변경 */
    outline: none; /* 포커스 효과 제거 */
  }

  body,
  button,
  a,
  input,
  textarea {
    font-family: $font-family-kr;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #eee;
    font-size: $font-size-default-px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: $font-letter-spacing-base;
  }
}

* {
  &[data-rune] {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

button {
  &[data-rune] {
    border-width: 0;
    background-color: transparent; /* 배경색 투명 */
    color: inherit; /* 부모 요소의 텍스트 색상 상속 */
    font-size: inherit; /* 부모 요소의 글꼴 크기 상속 */
    line-height: inherit; /* 부모 요소의 줄 간격 상속 */
    cursor: pointer; /* 마우스 커서를 포인터로 변경 */
    appearance: none; /* 버튼의 기본 스타일 제거 */
  }
}

a {
  &[data-rune] {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: inherit; /* 부모 요소의 텍스트 색상 상속 */
    text-decoration: none; /* 밑줄 제거 */
    cursor: pointer; /* 마우스 커서를 포인터로 변경 */
  }
}

input,
textarea {
  &[data-rune] {
    padding: 0; /* 내부 여백 */
    border-width: 0; /* 테두리 스타일 */
    color: inherit; /* 부모 요소의 텍스트 색상 상속 */
    font-family: $font-family-kr;
    font-size: inherit; /* 부모 요소의 글꼴 크기 상속 */
    line-height: inherit; /* 부모 요소의 줄 간격 상속 */
    appearance: none; /* 기본 스타일 제거 */
  }
}

input:focus,
textarea:focus {
  &[data-rune] {
    border-color: unset; /* 포커스시 테두리 색상 변경 */
    outline: none; /* 포커스 효과 제거 */
  }
}

input:disabled,
textarea:disabled {
  &[data-rune] {
    outline: none; /* 포커스 효과 제거 */
    background-color: unset; /* 포커스시 테두리 색상 변경 */
  }
}
